<template>
  <section class="section keep-footer-off-page">
    <div
      class="p-5 container column box is-one-third-desktop is-half-tablet is-one-quarter-widescreen"
    >
      <h1 class="py-4 heading has-text-centered">Login</h1>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <!-- use form so enter to submit works -->
        <form @submit.prevent="handleSubmit(login)" novalidate="true">
          <!-- email stuff -->
          <ValidationProvider
            rules="required|email"
            name="Email"
            v-slot="{ errors }"
          >
            <b-field
              label="Email"
              label-position="on-border"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
            >
              <b-input
                type="email"
                placeholder="john.smith@email.com"
                v-model="email"
              ></b-input>
            </b-field>
          </ValidationProvider>

          <!-- password stuff -->
          <ValidationProvider
            rules="required"
            vid="password"
            name="Password"
            v-slot="{ errors }"
          >
            <b-field
              class="mt-5"
              label="Password"
              label-position="on-border"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
            >
              <b-input
                type="password"
                v-model="password"
                placeholder="Password"
                password-reveal
              ></b-input>
            </b-field>
          </ValidationProvider>

          <!-- errors -->
          <b-message v-if="errorCode" type="is-danger" class="mt-3">
            {{ errorCode }}
          </b-message>

          <!-- Remember me -->
          <div class="field mt-3">
            <b-checkbox>Remember me</b-checkbox>
          </div>

          <!-- submit -->
          <button
            type="submit"
            v-bind:class="{
              'is-loading': isLoading,
            }"
            class="is-outlined mt-3 button is-primary"
          >
            Sign in
          </button>
        </form>
      </ValidationObserver>
      <hr />

      <!-- Alt Sign In providers -->
      <b-button @click="googleSignIn()" class="mt-1 button" icon-left="google">
        Sign in with Google
      </b-button>
      <b-button
        @click="facebookSignIn()"
        class="mt-1 button"
        icon-left="facebook"
      >
        Sign in with Facebook
      </b-button>

      <!-- Reroutes -->
      <div class="mt-3">
        <small class="text-muted">
          Don't have an account?
          <a
            class="ml-2"
            tabindex="0"
            @keyup.enter="$router.push({ name: 'Register' })"
            @click="$router.push({ name: 'Register' })"
            >Sign Up</a
          >
          <br />Forgot your password?
          <a
            class="ml-2"
            tabindex="0"
            @keyup.enter="$router.push({ name: 'Reset_request' })"
            @click="$router.push({ name: 'Reset_request' })"
            >Reset Password</a
          >
        </small>
      </div>
    </div>
  </section>
</template>

<script>
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";

import {
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";

setInteractionMode("passive");
export default {
  name: "login-view",
  components: {
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      email: "",
      password: "",
      error: "",
      errorCode: "",
      isLoading: false,
    };
  },

  methods: {
    startLoadingAnimations() {
      this.isLoading = true;
    },
    stopLoadingAnimations() {
      this.isLoading = false;
    },
    googleSignIn() {
      this.startLoadingAnimations();
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          // ...
          console.log("success logged in with Google");
          console.log(token, user);
          this.$buefy.toast.open("Log In Successful");
          this.stopLoadingAnimations();
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
          console.log("failed Google sign in");
          this.stopLoadingAnimations();
        });
    },
    facebookSignIn() {
      this.startLoadingAnimations();
      const provider = new FacebookAuthProvider();
      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then((result) => {
          // The signed-in user info.
          const user = result.user;
          // ...
          console.log("success logged in with Facebook");
          console.log(user);
          this.$buefy.toast.open("Log In Successful");
          this.stopLoadingAnimations();
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
          console.log("failed Facebook sign in");
          this.stopLoadingAnimations();

          // ...
        });
    },
    login() {
      this.startLoadingAnimations();
      signInWithEmailAndPassword(getAuth(), this.email, this.password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log(user);
          // ...
          this.$buefy.toast.open("Log In Successful");
          this.stopLoadingAnimations();
        })
        .catch((error) => {
          this.errorCode = error.code;
          this.error = error.message;
          this.stopLoadingAnimations();
        });
    },
  },

  created() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      // Check for user status
      if (user) {
        this.$router.replace(
          this.$route.query.redirect || { name: "Dashboard" }
        );
      }
    });
  },
};
</script>
